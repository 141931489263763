/**
 * IMPORTANT
 * 
 * Color variables are included in this file for the design system followed by bayada.com
 * The Color variables declared below apply for the entire application and compliment the
 * setup in the following files:
 * 1. apps/bayada/tailwind.config.js
 * 2. libs/shared/mui/mui-config/src/lib/mui.theme.tsx
 */

$ba-red-h: 356.27;
$ba-red-s: 71.08%;
$ba-red-l: 48.82%;
$ba-primary-red: hsl($ba-red-h, $ba-red-s, $ba-red-l);
$ba-primary-black: #232323;
$ba-btn-disabled: #c7c7c7;
$ba-btn-focus-bg: #ae0420;
$ba-field-hover-bg: #f3f3f3;
$ba-field-focus-bg: #eaeaea;
$text-link-color: var(--ba-green-600);

:export {
  ba-primary-black: $ba-primary-black;
  ba-primary-shade: hsl($ba-red-h, $ba-red-s, $ba-red-l);
  ba-btn-disabled: $ba-btn-disabled;
  ba-btn-focus-bg: $ba-btn-focus-bg;
  ba-field-hover-bg: $ba-field-hover-bg;
  ba-field-focus-bg: $ba-field-focus-bg;
  ba-text-link-color: $text-link-color;
}

$colors: (
  ba-primary: (
    'black': $ba-primary-black,
    'red': $ba-primary-red,
    100: #ffe0e4,
    200: #de5e73,
    400: #9c0e21,
    500: #780c1a,
    600: #ee0027,
    700: #c40a28,
    800: #ee536c,
    900: #ce0e2d
  ),
  ba-red: (
    'h': $ba-red-h,
    's': $ba-red-s,
    'l': $ba-red-l
  ),

  ba-blue: (
    50: #eff5fa,
    100: #d9eefb,
    150: #cfe9f9,
    200: #9bc4dc,
    300: #5c9cc1,
    400: #407c9f,
    500: #386178,
    600: #17315A
  ),
  ba-green: (
    100: #d9efed,
    200: #93cccc,
    300: #258e8e,
    350: #207e7e,
    400: #177373,
    450: #218383,
    500: #124d4d
  ),
  ba-purple: (
    500: #8B254F
  ),
  ba-gray: (
    50: #f6f7f7,
    75: #f7f7f7,
    85: #eeeeee,
    90: #e9e9e9,
    95: #ededed,
    100: #f1f1f2,
    150: #dadada,
    175: #d6d6d6,
    200: #d8d8da,
    250: #d3d3d6,
    300: #b9b9bc,
    400: #999999,
    425: #757575,
    450: #707070,
    500: #85807A,
    600: #7b7c7e,
    800: #606060,
    900: #545454
  )
);

$generic-colors: (
  white: #ffffff,
  black: #000000
);
